var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"编辑","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 14 },"wrapper-col":{ span: 10 }}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"运单号","label-col":{ span: 7 },"wrapper-col":{ span: 17 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'tracking_number',
                {
                  initialValue: _vm.item.tracking_number,
                  rules: [
                    { required: true, message: '请输入运单号' },
                    { max: 128, message: '超出最大长度(128)' },
                  ],
                },
              ]),expression:"[\n                'tracking_number',\n                {\n                  initialValue: item.tracking_number,\n                  rules: [\n                    { required: true, message: '请输入运单号' },\n                    { max: 128, message: '超出最大长度(128)' },\n                  ],\n                },\n              ]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最小重量(kg)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'min_weight',
                {
                  initialValue: _vm.item.min_weight,
                  rules: [{ required: true, message: '请输入最小重量' }],
                },
              ]),expression:"[\n                'min_weight',\n                {\n                  initialValue: item.min_weight,\n                  rules: [{ required: true, message: '请输入最小重量' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最大重量(kg)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'max_weight',
                {
                  initialValue: _vm.item.max_weight,
                  rules: [{ required: true, message: '请输入最大重量' }],
                },
              ]),expression:"[\n                'max_weight',\n                {\n                  initialValue: item.max_weight,\n                  rules: [{ required: true, message: '请输入最大重量' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最小长度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'min_length',
                {
                  initialValue: _vm.item.min_length,
                  rules: [{ required: true, message: '请输入最小长度' }],
                },
              ]),expression:"[\n                'min_length',\n                {\n                  initialValue: item.min_length,\n                  rules: [{ required: true, message: '请输入最小长度' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最大长度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'max_length',
                {
                  initialValue: _vm.item.max_length,
                  rules: [{ required: true, message: '请输入最大长度' }],
                },
              ]),expression:"[\n                'max_length',\n                {\n                  initialValue: item.max_length,\n                  rules: [{ required: true, message: '请输入最大长度' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最小宽度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'min_width',
                {
                  initialValue: _vm.item.min_width,
                  rules: [{ required: true, message: '请输入最小宽度' }],
                },
              ]),expression:"[\n                'min_width',\n                {\n                  initialValue: item.min_width,\n                  rules: [{ required: true, message: '请输入最小宽度' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最大宽度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'max_width',
                {
                  initialValue: _vm.item.max_width,
                  rules: [{ required: true, message: '请输入最大宽度' }],
                },
              ]),expression:"[\n                'max_width',\n                {\n                  initialValue: item.max_width,\n                  rules: [{ required: true, message: '请输入最大宽度' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最小高度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'min_height',
                {
                  initialValue: _vm.item.min_height,
                  rules: [{ required: true, message: '请输入最小高度' }],
                },
              ]),expression:"[\n                'min_height',\n                {\n                  initialValue: item.min_height,\n                  rules: [{ required: true, message: '请输入最小高度' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"最大高度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'max_height',
                {
                  initialValue: _vm.item.max_height,
                  rules: [{ required: true, message: '请输入最大高度' }],
                },
              ]),expression:"[\n                'max_height',\n                {\n                  initialValue: item.max_height,\n                  rules: [{ required: true, message: '请输入最大高度' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }